<template>
  <div class="info" v-if="props.data">

    <BrandDesc
      :info="props.data.description"
      :nm="props.data.name"
      :nmalt="props.data.name"
      :avatar="props.data.cover_s"
      :avatar-ratio="'free'"
      :items_photo="props.data.items_photo"
      />


    <BrandProductSetup v-if="props.data.item_product_setup"
      type="tent"
      :list="props.data.item_product_setup"
      />


      <div class="cpm"></div>


    <BrandItems
      :title="$t('strain_view_previously_released')"
      :showmore="$t('breeder_view_show_all_strains')"
      :products="productsOld"
      />

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('breeder_tab_info_awards')"
      :showmore="$t('product_view_diaries_show_all')"
      :list="props.data.items_diary.filter(b => {return b.group_name === 'win'})"
      :cnt="props.data.cnt_reports"
      :link="$route.path + '/diaries'"
      />

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('product_view_popular_diaries')"
      :showmore="$t('product_view_diaries_show_all')"
      :list="props.data.items_diary.filter(b => {return b.group_name === 'top'})"
      :cnt="props.data.cnt_reports"
      :link="$route.path + '/diaries'"
      />

    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('product_view_new_diaries')"
      :showmore="$t('product_view_diaries_show_all')"
      :list="props.data.items_diary.filter(b => {return b.group_name === 'new'})"
      :cnt="props.data.cnt_reports"
      :link="$route.path + '/diaries'"
      />

    <BrandHarvestItems
      :title="$t('strain_view_subtitle_latest_harvests')"
      :showmore="$t('breeder_view_show_all_harvests')"
      :link="$route.path + '/harvests'"
      :list="props.data.items_harvest"
      :cnt="props.data.cnt_harvests"
      />

    <BrandReviewItems
      :title="$t('breeder_view_latest_reviews')"
      :showmore="$t('breeder_view_show_all_reviews')"
      :link="$route.path + '/reviews'"
      :list="props.data.items_review"
      :cnt="props.data.cnt_reviews"
      />

    <BrandContestItems v-if="props.data.items_contest && props.data.items_contest.length"
      :title="$t('breeder_view_info_open_contests')"
      :list="props.data.items_contest.filter(b => {return b.group_name === 'running'})"
      />

    <BrandContestItems v-if="props.data.items_contest && props.data.items_contest.length"
      :title="$t('breeder_view_info_completed_contests')"
      :list="props.data.items_contest.filter(b => {return b.group_name === 'completed'})"
      />

    <BrandSimilarProducts
      :title="$t('breeder_view_info_sidebar_title')"
      :list="props.data.items_similar_brand"
      type="product"
      />

    <CommentWidget v-if="props.data.id"
      :title="$t('tents_latest_latest_comments')"
      :type="'product'"
      :content="props.data.id"
      />



  </div>
</template>

<script setup>

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
});

const productsOld = computed(() => {
  return getProductsList('old');
});


const getProductsList = (year) => {
  var arr = [];
  if(props.data.items_product)
    for(var item of props.data.items_product)
      if(year == item.group_name)
        arr.push(item);
  return arr;
};

</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
